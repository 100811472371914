<template>
	<div class="container the-menu" :class="{'menu-open': menuOpen}">
		<b-navbar-nav>
			<!--			<div class="align-items-center card flex-row mb-2 p-2" v-if="isOffline">-->
			<!--				<icon-signal-none size="sm" class="mr-2"></icon-signal-none> <span>Offline</span>-->
			<!--			</div>-->
			<b-nav-item :to="{ name: 'dashboard'}" @click="toggleMenu()">Home</b-nav-item>
			<b-nav-item :to="{ name: 'repairSearch'}" @click="toggleMenu()">Find a device</b-nav-item>
			<b-nav-item :to="{ name: 'deviceSearch'}" @click="toggleMenu()">Update device status</b-nav-item>
			<b-nav-item @click="logout()" class="mt-5"><i class="fa fa-power-off mr-2"></i> Log out</b-nav-item>
			<span class="version-number">Version: {{version}}</span>
		</b-navbar-nav>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
const { version } = require('../../../package.json')

export default {
	data() {
		return {
			version: version
		}
	},

	computed: {
		...mapGetters([
			"isSuperAdmin",
			"menuOpen"
		]),
	},

	methods: {
		...mapActions([
			'clearCarrier',
			'clearCarrierDetails',
			"toggleMenu",
		]),

		logout() {
			this.clearCarrier();
			this.clearCarrierDetails();
			window.location.href = '/login';
		}
	}
}
</script>

<style scoped>

</style>