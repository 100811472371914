<template>
    <div :class="'img-' + size" class="custom-icon">
		<svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><rect class="path" stroke="#0C2340" x="25" y="2" width="80" height="126" rx="10"/><path d="M47 118.5h36m-14-89h21m-21 31h17m-17 31h23M52.575 49.75h-12.75c-2.104 0-3.825 1.73-3.825 3.844v12.812c0 2.114 1.721 3.844 3.825 3.844h12.75c2.104 0 3.825-1.73 3.825-3.844M52.575 19h-12.75C37.721 19 36 20.73 36 22.844v12.812c0 2.114 1.721 3.844 3.825 3.844h12.75c2.104 0 3.825-1.73 3.825-3.844M36 97.156C36 99.27 37.721 101 39.825 101h12.75c2.104 0 3.825-1.73 3.825-3.844V84.344c0-2.114-1.721-3.844-3.825-3.844h-12.75C37.721 80.5 36 82.23 36 84.344v12.812zm7-68.387L47.167 33 58 22M43 59.77L47.167 64 58 53" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>