<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M8.024 116.211c3.896.072 9.03 2.234 12.957 3.982 7.815 3.476 17.54 7.803 29.423 7.807h.7c10.528 0 22.976-.102 28-7.318 2.064-2.967 2.57-6.653 1.512-10.984 4.015-4.196 6.168-12.024 4.032-18.397 4.317-5.766 4.711-13.814 2.29-19.557l.027-.027c2.927-2.94 4.805-7.692 4.785-12.107-.04-7.47-6.438-14.297-14.656-14.297H52.06c1.799-6.975 8.19-12.83 8.19-23.268C60.25 3.969 48.436 2 42.53 2c-4.966 0-7.255 9.67-8.35 14.223-1.28 5.332-2.606 10.845-6.249 14.495C19.941 38.722 15.75 48.921 6 58.554" class="path" stroke="#0C2340" transform="rotate(-180 48.875 65)"/><path d="M117.594 77.875a5.906 5.906 0 005.906-5.906V12.906A5.906 5.906 0 00117.594 7H97.907A5.906 5.906 0 0092 12.906V71.97a5.906 5.906 0 005.907 5.906h19.687z" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>