const state = {
    device: null,
    order: null,
    orders: []
};

const getters = {
    device: state => state.device,
    order: state => state.order,
    orders: state => state.orders
};

const actions = {
    loadDevice({commit}, searchValue) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/v4/partner/orders/device`, {searchValue: searchValue})
                .then(response => {
                    commit('SET_ORDER', response.data.data.order);
                    commit('SET_DEVICE', response.data.data.device);

                    resolve();
                })
                .catch(e => {
                    commit('CLEAR_ALL');
                    reject(e);
                });
        });
    },

    loadOrders({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            commit('CLEAR_ALL');

            this.$axios.post(`/api/v4/partner/orders`, payload)
                .then(response => {
                    commit('SET_ORDERS', response.data.data);

                    if (response.data.data.length === 1) {
                        dispatch('setOrder', response.data.data[0]).then(() => {
                            return resolve();
                        });
                    }

                    resolve();
                })
                .catch(e => {
                    commit('CLEAR_ALL');
                    reject(e);
                });
        });
    },

    setOrder({commit}, order) {
        return new Promise((resolve) => {
            commit('CLEAR_DEVICE');

            commit('SET_ORDER', order);

            if (order.devices.length === 1) {
                commit('SET_DEVICE', order.devices[0]);
            }

            resolve();
        });
    },

    setDevice({commit}, device) {
        return new Promise((resolve) => {
            commit('SET_DEVICE', device);
            resolve();
        });
    },

    updateDeviceStatus({commit, getters}, status) {
        return new Promise((resolve, reject) => {
            if (!getters.device) return reject('No device has been found');

            this.$axios.post(`/api/v4/partner/orders/device/${getters.device.reference}/update-status`, {status: status})
                .then(response => {
                    commit('SET_ORDER', response.data.data.order);
                    commit('SET_DEVICE', response.data.data.device);

                    resolve();
                })
                .catch(e => {
                    reject(e);
                });
        })
    },

    clearDevice({commit}) {
        commit('CLEAR_DEVICE');
    },

    clearOrderDetails({commit}) {
        commit('CLEAR_ALL');
    }
};

const mutations = {
    CLEAR_ALL(state) {
        state.device = null;
        state.order = null;
        state.orders = [];
    },

    CLEAR_DEVICE(state) {
        state.device = null;
    },

    SET_DEVICE(state, device) {
        state.device = device;
    },

    SET_ORDER(state, order) {
        state.order = order;
    },

    SET_ORDERS(state, orders) {
        state.orders = orders;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}