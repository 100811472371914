/**
 * State
 */
const state = {
    carrier: null,
    carrierDetails: {},
};

/**
 * Getters
 */
const getters = {
    /** Returns the carrier that we're logged in as, or null for WeFix */
    carrier: (state) => state.carrier,

    /** Return details about the carrier */
    carrierDetails: (state) => state.carrierDetails
};

/**
 * Actions
 */
const actions = {
    clearCarrier({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_CARRIER');
            resolve();
        })
    },

    clearCarrierDetails({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_CARRIER_DETAILS');
            resolve();
        })
    },

    setCarrier({commit}, user) {
        return new Promise((resolve) => {
            let carrier = null;
            let companyCode = user.company.code;

            if (companyCode) {
                carrier = companyCode;
            }

            commit('SET_CARRIER', carrier);

            let carrierData = {};

            switch (carrier) {
                case "ee": {
                    carrierData = {
                        logoPath: "ee-colour",
                        name: "ee",
                    }
                    break;
                }
                case "o2": {
                    carrierData = {
                        logoPath: "o2-white",
                        name: "o2",
                    }
                    break;
                }
                case "tesco": {
                    carrierData = {
                        logoPath: "tesco-mobile-colour",
                        name: "tesco-mobile",
                    }
                    break;
                }
                case "three": {
                    carrierData = {
                        logoPath: "three-colour",
                        name: "three",
                    }
                    break;
                }
                case "vodafone": {
                    carrierData = {
                        logoPath: "vodafone-white",
                        name: "vodafone",
                    }
                    break;
                }
                default: {
                    carrierData = {
                        logoPath: "wefix-white",
                        name: "wefix",
                    }
                    break;
                }
            }

            commit('SET_CARRIER_DETAILS', carrierData);

            resolve();
        })
    }
};

/**
 * Mutations
 */
const mutations = {
    CLEAR_CARRIER(state) {
        state.carrier = null;
    },

    CLEAR_CARRIER_DETAILS(state) {
        state.carrierDetails = {};
    },

    SET_CARRIER(state, carrier) {
        state.carrier = carrier;
    },

    SET_CARRIER_DETAILS(state, data) {
        state.carrierDetails = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}