<template>
	<div v-if="hasOrder" @click="selectOrder()" class="card card-body card-link table-layout">
		<div class="card-cell">{{ order.first_name }} {{ order.second_name }}</div>
		<div class="card-cell" v-if="isSuperAdmin">{{ order.company.name }}</div>
		<div class="card-cell">{{ order.company.store }} <template v-if="order.company.store_code">({{ order.company.store_code }})</template></div>
		<div class="card-cell">{{ order.devices[0].model_title }} {{ deviceCount ? `+ ${deviceCount} more` : '' }}</div>
<!--		<div class="card-cell status"> TODO: Might be multiple devices so let's leave this out for now-->
<!--			<div class="badge badge-secondary">Awaiting drop-off</div>-->
<!--		</div>-->
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'

	export default {
		name: "SearchResultItem",

		props: {
			order: {
				type: Object,
				default: function() {
					return {};
				}
			}
		},

		computed: {
			...mapGetters(['device', 'isSuperAdmin']),

			hasOrder() {
				return Object.keys(this.order).length > 0;
			},

			deviceCount() {
				if (!this.hasOrder) return 0;

				return this.order.devices.length - 1;
			}
		},

		methods: {
			...mapActions(['setOrder']),

			selectOrder() {
				this.setOrder(this.order).then(() => {
					this.$router.push({name: `dropOffDevice.confirmDevice`});
				});
			}
		}
	}
</script>

<style scoped>

</style>