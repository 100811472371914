import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../vuex/store';
import User from '../services/user';

Vue.use(VueRouter);

const Auth = () => import('Views/auth/LoginIndex');

const Dashboard = () => import('Views/dashboard/DashboardIndex');

const DeviceSearch = () => import('Views/deviceSearch/DeviceSearchIndex');

const DropOffBookingConfirmation = () => import('Views/dropOff/BookingConfirmation');
const DropOffConfirmDevice = () => import('Views/dropOff/DeviceConfirm');
const DropOffDevice = () => import('Views/dropOff/DropOffDeviceIndex');
const RepairSearch = () => import('Views/dropOff/RepairSearch');
const repairSearchResults = () => import('Views/dropOff/SearchResults');

const Test = () => import('Views/test/TestPage');

let user = new User(store.getters.user);

const routes = [
    /** Auth */
    {
        path: '/login',
        name: 'login',
        component: Auth,
        meta: {title: 'Login'},
        beforeEnter: (to, from, next) => {
            store.dispatch('clearUser');
            next();
        }
    },
    {
        path: '/logout',
        name: 'logout',
        redirect: 'login'
    },

    /** Dashboard */
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {title: 'Dashboard'}
    },

    /** Device Search */
    {
        path: '/device-search',
        name: 'deviceSearch',
        component: DeviceSearch,
        meta: {title: 'Device search'}
    },

    /** Repair Search */
    {
        path: '/repair-search',
        name: 'repairSearch',
        component: RepairSearch,
        meta: {title: 'Repair search'}
    },

    /** Repair Search Results */
    {
        path: '/repair-search/results',
        name: 'repairSearchResults',
        component: repairSearchResults,
        meta: {title: 'Search results'}
    },

    /** Drop-Off */
    {
        path: '/repair/drop-off',
        name: 'dropOffDevice',
        component: DropOffDevice,
        redirect: {name: 'dropOffDevice.confirmDevice'},
        children: [
            /** Confirm booking */
            {
                path: 'confirm-booking',
                name: 'dropOffDevice.confirmBooking',
                component: DropOffBookingConfirmation,
                meta: {
                    middleware: 'isStore',
                    title: 'Confirm booking'
                }
            },
            /** Confirm device */
            {
                path: 'confirm-device',
                name: 'dropOffDevice.confirmDevice',
                component: DropOffConfirmDevice,
                meta: {
                    middleware: 'isStore',
                    title: 'Confirm device'
                }
            }
        ]
    },

    /** Test */
    {
        path: '/test',
        component: Test,
    }

];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior () {
        Vue.nextTick(function () {
            return {x: 0, y: 0};
        })
    },
});

router.beforeEach((to, from, next) => {

    document.title = to.meta.title;

    window.scrollTo(0, 0);

    to.matched.some(record => {
        if (record.meta.middleware === 'isStore') {
            if (!store.getters.hasAppAccess) {
                window.location.href = '/dashboard';
            }
        }

        if (record.name !== 'login' && !user.details.id) {
            store.dispatch('clearUser');
            next('/login');
        }
        else next();
    });

});

router.afterEach(() => {
    window.scrollTo(0,0)
});

export default router
