import Vue from 'vue'

/**
 * State
 */
const state = {
    menuOpen: false
};

/**
 * Getters
 */
const getters = {
    menuOpen: state => state.menuOpen
};

/**
 * Actions
 */
const actions = {

    /** Display toast */
    displayToast(_, {text, type = 'info', duration = 5000, dismissible = true}) {
        return new Promise((resolve) => {
            type = (type === 'danger') ? 'error' : type;

            let toastSettings = {
                containerClass: "custom-toast",
                position: "top-right",
                type: type,
                duration: duration,
                closeOnSwipe: true,
                icon: {
                    name: ''
                }
            };

            if (dismissible) {
                toastSettings.action = {
                    onClick : (e, toastObject) => {
                        toastObject.goAway(0);
                    }
                };
            }

            switch (type) {
                case 'success':
                    toastSettings.icon.name = 'fal fa-check-circle';
                    break;
                case 'warning':
                    toastSettings.icon.name = 'fal fa-exclamation-circle';
                    break;
                case 'danger':
                case 'error':
                    toastSettings.icon.name = 'fal fa-times-circle';
                    break;
                default:
                    toastSettings.icon.name = 'fal fa-info-circle';
            }

            Vue.toasted.show(text, toastSettings);

            resolve();
        });
    },

    loadCss(_, {carrier}) {
        return new Promise((resolve) => {
            if (carrier) {
                import('@/styles/core/whitelabel/' + carrier + '.scss');
            } else {
                import('@/styles/core/whitelabel/default.scss');
            }
            resolve();
        })
    },

    /** Toggle the main menu */
    toggleMenu({commit, state}) {
        if (state.menuOpen === false) {
            commit('OPEN_MENU');
            return;
        }

        commit('CLOSE_MENU');
    }
};

/**
 * Mutations
 */
const mutations = {
    OPEN_MENU(state) {
        state.menuOpen = true;
    },

    CLOSE_MENU(state) {
        state.menuOpen = false;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}