<template>
    <div class="row">
        <div class="col-12">
            <h1 class="title">Search for the repair</h1>
        </div>
        <div class="col-12">
			<b-tabs fill>
				<!-- Reference -->
				<b-tab title="Reference" active>
					<div class="row">
						<div class="col">
							<div class="form-group mb-3">
								<div class="input-group">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="fa fa-search mb-0"></i></span>
									</div>
									<input type="text" inputmode="numeric" pattern="[0-9]*" class="form-control" placeholder="Enter order reference" v-on:keyup.enter="goToOrdersByReference()" v-model="reference">
									<span class="input-clear" v-if="reference" @click="clearInputs('reference')"><i class="fas fa-times-circle mb-0"></i></span>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2 mt-3 mt-md-0 pl-md-0">
							<button class="btn continue-btn search-btn btn-block mb-0" @click="goToOrdersByReference()" :disabled="!reference || loading">
								<template v-if="loading"><animated-loading size="sm"></animated-loading></template>
								<template v-if="!loading">Search</template>
							</button>
						</div>
					</div>
				</b-tab>

				<!-- IMEI -->
				<b-tab title="IMEI">
					<div class="row">
						<div class="col">
							<div class="form-group mb-3">
								<div class="input-group">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="fa fa-search mb-0"></i></span>
									</div>
									<input type="text" inputmode="numeric" pattern="[0-9]*" class="form-control" placeholder="Enter device IMEI" v-on:keyup.enter="goToOrdersByImei()" v-model="imei">
									<span class="input-clear" v-if="imei" @click="clearInputs('imei')"><i class="fas fa-times-circle mb-0"></i></span>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2 mt-3 mt-md-0 pl-md-0 border-top-0">
							<button class="btn continue-btn search-btn btn-block mb-0" @click="goToOrdersByImei()" :disabled="!imei || loading">
								<template v-if="loading"><animated-loading size="sm"></animated-loading></template>
								<template v-if="!loading">Search</template>
							</button>
						</div>
					</div>
				</b-tab>

				<!-- Customer -->
				<b-tab title="Customer">
					<div class="row">
						<div class="col-12 col-md-5">
							<div class="form-group mb-3">
								<div class="input-group">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="fa fa-user mb-0"></i></span>
									</div>
									<input type="text" class="form-control" placeholder="Customer surname" v-on:keyup.enter="goToOrdersByDetails()" v-model="customerSurname">
									<span class="input-clear" v-if="customerSurname" @click="clearInputs('customerSurname')"><i class="fas fa-times-circle mb-0"></i></span>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-5">
							<div class="form-group mb-3">
								<div class="input-group">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="fa fa-map-marker-alt mb-0"></i></span>
									</div>
									<input type="text" class="form-control postcode-search" placeholder="Postcode" v-on:keyup.enter="goToOrdersByDetails()" v-model="customerPostcode">
									<span class="input-clear" v-if="customerPostcode" @click="clearInputs('customerPostcode')"><i class="fas fa-times-circle mb-0"></i></span>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-2 mt-3 mt-md-0 pl-md-0">
							<button class="btn continue-btn search-btn btn-block mb-0" @click="goToOrdersByDetails()" :disabled="!customerSurname && !customerPostcode || loading">
								<template v-if="loading"><animated-loading size="sm"></animated-loading></template>
								<template v-if="!loading">Search</template>
							</button>
						</div>
					</div>
				</b-tab>
			</b-tabs>
        </div>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {
		data() {
            return {
				customerSurname: null,
                customerPostcode: null,
                imei: null,
                reference: null,
				loading: false
            }
        },

		computed: {
			...mapGetters(['device', 'order', 'orders'])
		},

		mounted() {
			this.clearOrderDetails();
		},

		methods: {
			...mapActions(['clearOrderDetails', 'displayToast', 'loadOrders']),

			clearInputs(input) {
				this[input] = null;
			},

			/** Create a json payload based on the order reference */
			goToOrdersByReference() {
				let payload = {
					reference: this.reference
				};

				this.goToOrders(payload);
			},

			/** Create a json payload based on the order imei */
			goToOrdersByImei() {
				let payload = {
					imei: this.imei
				};

				this.goToOrders(payload);
			},

			/** Create a json payload based on the customer surname and postcode */
			goToOrdersByDetails() {
				let payload = {
					last_name: this.customerSurname,
					postcode: this.customerPostcode
				};

				this.goToOrders(payload);
			},

            /** Find the job and move on */
			goToOrders(payload) {
				if (this.loading) return;

				this.loading = true;

				this.loadOrders(payload).then(() => {
					this.loading = false;

					if (this.orders.length > 1) {
						// View a list of orders to select from
						this.$router.push({name: `repairSearchResults`});
					} else {
						// Go direct to the order
						this.$router.push({name: `dropOffDevice.confirmDevice`});
					}
				}).catch(e => {
					this.loading = false
					this.displayToast({text: e.response.data.messages[0], type: 'error'});
				})
			}
        }
    }
</script>