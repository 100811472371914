<template>
	<div id="app-container" :class="{'menu-open': menuOpen}">
		<loading-state v-if="loading" fullscreen></loading-state>

		<!-- Navbar -->
		<the-navbar v-if="user.id && !loading"></the-navbar>
		<!-- Content -->
		<main>
			<transition
				name="fade"
				mode="out-in">
				<router-view class="router-view" :class="{'menu-open': menuOpen}"></router-view>
			</transition>
		</main>
		<!-- Menu -->
		<the-menu v-if="user.id && !loading"></the-menu>
	</div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
	beforeCreate: function() {
		document.body.className = 'home';
	},

	data() {
		return {
			loading: true
		}
	},

	computed: {
		...mapGetters([
			'carrier',
			'menuOpen',
			'user'
		]),
	},

	mounted() {
		this.loading = true;
		this.clearOrderDetails();

		if (this.user.id) {
			this.setCarrier(this.user)
				.then(() => {
					this.loadCss({user: this.user, carrier: this.carrier})
						.then(() => {
							setTimeout(() => {
								this.loading = false;
							}, 1500);
						});
				});
		} else {
			this.loading = false;
		}
	},

	created() {
		// this.$on("online", () => {
		//   this.displayToast({text: 'You\'re online', type: 'success'});
		//   this.isOnline = true;
		// });
		//
		// this.$on("offline", () => {
		//   this.displayToast({text: 'You\'ve gone offline', type: 'warning'});
		//   this.isOnline = false;
		// });
	},

	watch: {
		'$route' (to) {
			document.title = to.meta.title || 'WeFix Admin App'
		}
	},

	methods: {
		...mapActions([
			'clearOrderDetails',
			'displayToast',
			'loadCss',
			'setCarrier',
			'toggleMenu',
		]),
	}
}
</script>
<!--<style lang="scss">-->
<!--    @import 'styles/imports.scss';-->
<!--</style>-->