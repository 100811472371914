import Vue from 'vue';
import Vuex from 'vuex';
import persisted from 'vuex-persistedstate';

import carrier from './modules/carrier';
import globals from './modules/globals';
import order from './modules/order';
import user from './modules/user';

Vue.use(Vuex);

const store = new Vuex.Store({
    plugins: [
        persisted({
            paths: [
                'carrier',
                'order',
                'user'
            ]
        })
    ],
    state: {},
    modules: {
        carrier,
        globals,
        order,
        user
    }
});

export default store;