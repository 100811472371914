<template>
    <div>
        <loading-state
                v-show="loading"
                title="Loading scanner">
        </loading-state>

        <empty-state
                v-show="!loading && error"
                statusIcon="error"
                title="There was an error"
                :subtitle="error">
        </empty-state>

        <qrcode-stream v-show="!loading && !error"
                       @init="handleOnInitEvent"
                       @decode="handleSuccessfulScan">
        </qrcode-stream>
    </div>
</template>

<script>
    import { QrcodeStream } from 'vue-qrcode-reader'

    export default {
        name: "QrCodeScanner",

        components: {
            QrcodeStream
        },

        data() {
            return {
                loading: true,
                error: null,
            }
        },

        methods: {
            handleSuccessfulScan(data) {
                if(data) {
                    this.$emit('scanned', JSON.parse(data));
                }
            },

            async handleOnInitEvent(promise) {
                this.loading = true;

                try {
                    await promise;

                } catch (error) {
                    if (error.name === 'NotAllowedError') {
                        this.error = "ERROR: you need to grant camera access permisson"
                    } else if (error.name === 'NotFoundError') {
                        this.error = "ERROR: no camera on this device"
                    } else if (error.name === 'NotSupportedError') {
                        this.error = "ERROR: secure context required (HTTPS, localhost)"
                    } else if (error.name === 'NotReadableError') {
                        this.error = "ERROR: is the camera already in use?"
                    } else if (error.name === 'OverconstrainedError') {
                        this.error = "ERROR: installed cameras are not suitable"
                    } else if (error.name === 'StreamApiNotSupportedError') {
                        this.error = "ERROR: Stream API is not supported in this browser"
                    }
                } finally {
                    this.loading = false;
                }
            }
        }
    }
</script>