import Vue from 'vue'

/** Drop off **/
Vue.component('repair-search', require('Components/dropOff/RepairSearch').default);
Vue.component('repair-search-results', require('Components/dropOff/SearchResultItem').default);

/** Animated Icons */
Vue.component('animated-error', require('Components/animatedIcons/ErrorAnimated').default);
Vue.component('animated-info', require('Components/animatedIcons/InfoAnimated').default);
Vue.component('animated-loading', require('Components/animatedIcons/LoadingAnimated').default);
Vue.component('animated-success', require('Components/animatedIcons/SuccessAnimated').default);
Vue.component('animated-warning', require('Components/animatedIcons/WarningAnimated').default);

/** Icons */
Vue.component('icon-ber', require('Components/icons/Ber').default);
Vue.component('icon-book-repair', require('Components/icons/BookRepair').default);
Vue.component('icon-box', require('Components/icons/Box').default);
Vue.component('icon-calendar', require('Components/icons/Calendar').default);
Vue.component('icon-calendar-check', require('Components/icons/CalendarCheck').default);
Vue.component('icon-camera', require('Components/icons/Camera').default);
Vue.component('icon-checklist', require('Components/icons/Checklist').default);
Vue.component('icon-cogs', require('Components/icons/Cogs').default);
Vue.component('icon-device-back', require('Components/icons/DeviceBack').default);
Vue.component('icon-device-front', require('Components/icons/DeviceFront').default);
Vue.component('icon-device-received', require('Components/icons/DeviceReceived').default);
Vue.component('icon-device-status', require('Components/icons/DeviceStatus').default);
Vue.component('icon-device-unknown', require('Components/icons/DeviceUnknown').default);
Vue.component('icon-inbound-shipment', require('Components/icons/InboundShipment').default);
Vue.component('icon-in-house-repair', require('Components/icons/InHouseRepair').default);
Vue.component('icon-invoice', require('Components/icons/Invoice').default);
Vue.component('icon-map', require('Components/icons/Map').default);
Vue.component('icon-mobile', require('Components/icons/Mobile').default);
Vue.component('icon-outbound-shipment', require('Components/icons/OutboundShipment').default);
Vue.component('icon-overtime', require('Components/icons/Overtime').default);
Vue.component('icon-printer', require('Components/icons/Printer').default);
Vue.component('icon-repair-kits-packing', require('Components/icons/RepairKitsPacking').default);
Vue.component('icon-repair-kits-picking', require('Components/icons/RepairKitsPicking').default);
Vue.component('icon-repair-kits-returns', require('Components/icons/RepairKitsReturns').default);
Vue.component('icon-refresh', require('Components/icons/Refresh').default);
Vue.component('icon-reload', require('Components/icons/Reload').default);
Vue.component('icon-scanner', require('Components/icons/Scanner').default);
Vue.component('icon-search-imei', require('Components/icons/SearchImei').default);
Vue.component('icon-serial-number', require('Components/icons/SerialNumber').default);
Vue.component('icon-signal-none', require('Components/icons/SignalNone').default);
Vue.component('icon-spreadsheet', require('Components/icons/Spreadsheet').default);
Vue.component('icon-stock-pick', require('Components/icons/StockPick').default);
Vue.component('icon-switch-camera', require('Components/icons/SwitchCamera').default);
Vue.component('icon-tablet', require('Components/icons/Tablet').default);
Vue.component('icon-thumbs-down', require('Components/icons/ThumbsDown').default);
Vue.component('icon-thumbs-up', require('Components/icons/ThumbsUp').default);
Vue.component('icon-ticket', require('Components/icons/Ticket').default);
Vue.component('icon-toolbox', require('Components/icons/Toolbox').default);
Vue.component('icon-tools', require('Components/icons/Tools').default);
Vue.component('icon-van-clean', require('Components/icons/VanClean').default);
Vue.component('icon-warranty', require('Components/icons/Warranty').default);

/** Misc */
Vue.component('qr-code-scanner', require('Components/scanner/QrCodeScanner').default);
Vue.component('empty-state', require('Components/misc/EmptyState').default);
Vue.component('loading-state', require('Components/misc/LoadingState').default);
Vue.component('search-banner', require('Components/misc/SearchBanner').default);
Vue.component('the-menu', require('Components/misc/TheMenu').default);
Vue.component('the-navbar', require('Components/TheNavbar').default);