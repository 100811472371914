const state = {
    user: {}
};

const getters = {
    user: state => state.user,

    hasAppAccess: state => state.user.company.app_access_details && state.user.company.app_access_details.has_access,
    isStore: state => getters.hasAppAccess && state.user.company.app_access_details.access_type === 'storeAppAccess',
    isSuperAdmin: state => state.user.role.tag === 'superAdmin',
};

const actions = {
    fetchUser({commit}, {email, password}) {
        return new Promise((resolve, reject) => {
            this.$axios.post(
                '/api/login',
                {
                    email: email,
                    password: password
                })
                .then(response => {

                    let user = response.data.data;

                    if (
                        user.role.tag !== 'superAdmin' &&
                        (
                            !user.company.app_access_details.has_access ||
                            user.company.app_access_details.access_type !== 'storeAppAccess'
                        )
                    ) {
                        reject('You are not authorised to use this app.');
                    }

                    commit('SET_USER', user);
                    resolve();
                })
                .catch(errors => {
                    reject(errors.response.data.messages[0]);
                });
        });
    },

    refreshUser({commit}) {
        return new Promise((resolve, reject) => {
            this.axios.get('/api/login/user').then(response => {
                let user = response.data.data;
                if (user.company.name !== 'Reviveaphone') {
                    reject('You are not authorised to use this app.');
                }

                commit('SET_USER', user);
                resolve();
            })
            .catch(errors => {
                reject(errors.response.data.messages[0]);
            });
        });
    },

    clearUser({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_USER');
            resolve();
        })
    }
};

const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },

    CLEAR_USER(state) {
        state.user = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}