<template>
    <div class="loading-state" :class="{ 'centered': centered, 'fullscreen': fullscreen, 'overlay': overlay, 'inline': inline,'sm': small }">

        <!-- Loading Icon -->
        <animated-loading :size="loadingIconSize"></animated-loading>

        <!-- Title -->
        <h2 v-if="title" v-html="title"></h2>

        <!-- Subtitle -->
        <h3 v-if="subtitle" v-html="subtitle"></h3>

    </div>
</template>

<script>
    export default {
        props: {
            centered: {
                type: Boolean,
                default: false
            },
            fullscreen: {
                type: Boolean,
                default: false
            },
            inline: {
                type: Boolean,
                default: false
            },
            loadingIconSize: {
                type: String,
                default: "3x"
            },
            overlay: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            subtitle: {
                type: String,
                default: "Hang tight!"
            },
            title: {
                type: String,
                default: "Loading..."
            }
        }
    }
</script>

<style lang="scss" scoped>
.loading-animated {
	animation: loading 3s linear infinite;
	transform-origin: center;
	height: 96px;
	width: 96px;

	::v-deep .path {
		stroke: {
			dashoffset: 0;
			dasharray: 310;
			linecap: round;
		}
		transform-origin: center;
		animation: loading-circle 2s linear infinite;
		stroke: #636c72;
	}
}

.status-icon svg {
	vertical-align: initial;
}

.loading-state {
	align-items: center;
	background-color: #F8F8F8;
	display: flex;
	flex-direction: column;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	justify-content: center;
	padding: 2rem;
	z-index: 1040;

	&.fullscreen {
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
	}

	&.overlay {
		background-color: rgba(#F8F8F8, 0.93);
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;
	}

	h2 {
		color: #252b2b;
		margin-top: 30px;
	}

	h3 {
		color: #636c72;
		font-weight: normal;
		margin-top: 0;
	}
}

.card,
.modal {
	.loading-overlay {
		background-color: #ffffff;
	}
}

@keyframes loading {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes loading-circle {
	0% {
		stroke-dashoffset: 640;
	}
	100% {
		stroke-dashoffset: 0;
	}
}
</style>