<template>
    <div :class="'img-' + size" class="custom-icon">
		<svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M82 91h17M70 50.154L78.889 59 102 36" class="highlight" stroke="#00BBDC"/><path d="M26 83l31-4c6-.667 11 .667 15 4s9.667 8 17 14c1.333 1.333 1.333 3 0 5-2 3-5 3-8 2-2.805-.935-7.333-4-16-8-2.667-1.333-4-2-5-1-2 2 1 3 3 4 1.333.667 8.333 4.333 21 11 3.333 1.333 6.667 1.667 10-1s12-9.667 26-21c2.667-2 5-2 7 0s1.333 5-2 9l-27 27c-3.333 3.333-7.667 4.667-13 4-5.333-.667-28.333-3-59-7M4 77h20a2 2 0 012 2v46a2 2 0 01-2 2H4a2 2 0 01-2-2V79a2 2 0 012-2zm51 1.648V12c0-5.523 4.477-10 10-10h41c5.523 0 10 4.477 10 10v79m-10 8H90.175" class="path" stroke="#0C2340"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>