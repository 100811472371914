<template>
    <div class="empty-state" :class="[{'empty-icon': emptyIcon, 'inline': inline, 'sm': small, 'xs': extraSmall}, 'align-' + align, 'icon-' + iconAlign]">

        <!-- Icon - MUST use a custom icon from the "icons" directory -->
        <component
                v-if="icon"
				:class="iconClass"
                :is="'icon-' + icon"
                :size="iconSize">
        </component>

        <!-- Animated Status Icons -->
        <component
                v-if="statusIcon"
				:class="iconClass"
                :is="'animated-' + statusIcon"
                :size="iconSize">
        </component>

        <!-- Font Awesome Icon -->
        <i
                v-if="faIcon"
                class="fal"
                :class="['fa-' + faIcon, iconClass]">
        </i>

        <div>
            <!-- Title -->
            <h2 v-if="title" v-html="title"></h2>

            <!-- Subtitle -->
            <h3 v-if="subtitle" v-html="subtitle"></h3>
        </div>

        <!-- Content -->
        <p v-if="content" v-html="content"></p>

        <slot></slot>

    </div>
</template>

<script>
    export default {
        name: "EmptyState",

        props: {
            align: {
                type: String,
                default: "center"
            },
            content: {
                type: String,
                default: ""
            },
            emptyIcon: {
                type: Boolean,
                default: false
            },
            faIcon: {
                type: String,
                default: ""
            },
            icon: {
                type: String,
                default: ""
            },
            iconAlign: {
                type: String,
                default: "center"
            },
            iconClass: {
                type: String,
                default: null
            },
            iconSize: {
                type: String,
                default: "4x"
            },
            inline: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            statusIcon: {
                type: String,
                default: ""
            },
            subtitle: {
                type: String,
                default: ""
            },
            title: {
                type: String,
                default: "Nothing to See Here..."
            },
            extraSmall: {
                type: Boolean,
                default: false
            },
        }

    }
</script>