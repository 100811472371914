import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/vuex/store';
import BootstrapVue from 'bootstrap-vue'
import VueHtmlToPaper from 'vue-html-to-paper';
import * as VueGoogleMaps from 'vue2-google-maps'
import VueToasted from 'vue-toasted';
import User from "./services/user";
import Axios from 'axios';
import 'Components'

//User
let user = new User(store.getters.user);

let axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URI,
  headers: {
    Authorization: `Bearer ${user.apiKey()}`,
    Accept: 'application/json'
  }
});

store.$axios = axios;

import '@/registerServiceWorker'

Vue.use(BootstrapVue);
Vue.use(VueHtmlToPaper);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBdZcrpWfXStW2Uui8iweJYvwCv18xvrYg',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  }
});
Vue.use(VueToasted, {
  iconPack: 'custom-class' // set your iconPack, defaults to material. material|fontawesome|custom-class
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

Vue.config.productionTip = false;

window.Vue = Vue;