<template>
	<div>
		<b-navbar toggleable="true" sticky v-if="!isLogin">
			<div class="container py-0">
				<div class="nav-controls">
					<!-- Back buttons -->
					<div v-if="isDashboard || menuOpen" class="back-button"></div> <!-- Empty div for dashboard -->
					<template v-if="!isDashboard && !menuOpen">
						<router-link v-if="backToDashboard" :to="{ name: 'dashboard'}" class="back-button"><i class="fal fa-arrow-left"></i></router-link>
						<router-link v-if="isConfirmDevice || isSearchResults" :to="{ name: 'repairSearch'}" class="back-button"><i class="fal fa-arrow-left"></i></router-link>
						<router-link v-if="isConfirmBooking" :to="{ name: 'dropOffDevice.confirmDevice'}" class="back-button"><i class="fal fa-arrow-left"></i></router-link>
					</template>

					<!-- Title -->
					<b-nav-text class="title">{{ pageTitle }}</b-nav-text>

					<div class="nav-right">
						<!-- Signal status -->
<!--						<icon-signal-none class="signal-icon" v-if="isOffline && !menuOpen" size="md"></icon-signal-none>-->

						<!-- Navbar toggle -->
						<span class="navbar-toggler" @click="toggleMenu()" :class="{'active': menuOpen}">
                            <span class="top"></span>
                            <span class="middle"></span>
                            <span class="bottom"></span>
                        </span>
					</div>

				</div>
			</div>
		</b-navbar>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"

export default {
	name: "TheNavbar",

	computed: {
		...mapGetters([
			'isSuperAdmin',
			'menuOpen'
		]),

		backToDashboard() {
			return (
				!this.isConfirmBooking &&
				!this.isConfirmDevice &&
				!this.isDashboard &&
				!this.isLogin &&
				!this.isSearchResults
			);
		},

		isConfirmBooking() {
			if (!this.$route.matched[1]) {
				return false;
			}
			return this.$route.matched[1].name === `dropOffDevice.confirmBooking`;
		},

		isConfirmDevice() {
			if (!this.$route.matched[1]) {
				return false;
			}
			return this.$route.matched[1].name === `dropOffDevice.confirmDevice`;
		},

		isDashboard() {
			if (!this.$route.matched[0]) {
				return false;
			}
			return this.$route.matched[0].name === `dashboard`;
		},

		isLogin() {
			if (!this.$route.matched[0]) {
				return false;
			}
			return this.$route.matched[0].name === `login`;
		},

		isSearchResults() {
			if (!this.$route.matched[0]) {
				return false;
			}
			return this.$route.matched[0].name === `repairSearchResults`;
		},

		pageTitle: function() {
			for (let i in this.$route.params) {
				if (i.includes('Id')) {
					let title = `${this.$route.meta.title} ${this.$route.params[i]}`;
					document.title = title;
					return title;
				}
			}

			return this.$route.meta.title;
		},
	},

	methods: {
		...mapActions([
			'toggleMenu',
		]),
	}
}
</script>

<style scoped>

</style>