<template>
    <div :class="'img-' + size" class="custom-icon">
        <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M40.275 116.211c3.895.072 9.03 2.234 12.957 3.982 7.814 3.476 17.54 7.803 29.423 7.807h.7c10.527 0 22.976-.102 27.999-7.318 2.065-2.967 2.57-6.653 1.513-10.984 4.014-4.196 6.168-12.024 4.031-18.397 4.318-5.766 4.711-13.814 2.29-19.557l.028-.027c2.927-2.94 4.804-7.692 4.784-12.107-.039-7.47-6.437-14.297-14.656-14.297H84.311c1.798-6.975 8.189-12.83 8.189-23.268C92.5 3.969 80.687 2 74.781 2c-4.967 0-7.256 9.67-8.35 14.223-1.281 5.332-2.606 10.845-6.25 14.495-7.99 8.004-12.182 18.203-21.93 27.836" class="path" stroke="#0C2340"/><path d="M31.594 122.875a5.906 5.906 0 005.906-5.906V57.906A5.906 5.906 0 0031.594 52H11.906A5.906 5.906 0 006 57.906v59.063a5.906 5.906 0 005.906 5.906h19.688z" class="highlight" stroke="#00BBDC"/></g></svg>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>